import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {Title} from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import {PDFDocument} from 'pdf-lib';
import {SelectReasonModalComponent} from "./select-reason-modal/select-reason-modal.component";
import download from "downloadjs";
import {ProductStatusEnum} from "../../core/enums/product-status.enum";
import {ReturnsStore} from "../../core/store/returns.state";
import {PrintLabelComponent} from '../your-return/print-label/print-label.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DecimalPipe, NgFor, NgIf, UpperCasePipe} from '@angular/common';
import {MatDialog} from "@angular/material/dialog";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {take} from "rxjs";
import {ErrorNotificationModalComponent} from "../error-notification-modal/error-notification-modal.component";
import { HttpErrorResponse } from "@angular/common/http";

declare const $: any;
declare const JsBarcode: any;

@Component({
	selector: 'app-order-view',
	templateUrl: './order-view.component.html',
	styleUrls: ['./order-view.component.scss'],
	standalone: true,
	imports: [NgFor, NgIf, ReactiveFormsModule, FormsModule, PrintLabelComponent, UpperCasePipe, DecimalPipe, TranslateModule]
})
export class OrderViewComponent implements OnInit {
	private _store = inject(ReturnsStore);
	private _apiService = inject(ApiService);
	private _route = inject(ActivatedRoute);
	private _router = inject(Router);
	private _titleService = inject(Title);
	private _dialog = inject(MatDialog);
	
	form = this._store.getCurrentState().hasForm;
	productBrand = this._route.snapshot.params.brand;
	items: any = [];
	client: any = {};
	order_header: any = {};
	data: object = {};
	email: string = '';
	order_num: string = '';
	returns: any;
	request: any;
	general_data: any;
	returnReasons = ['-', ...this._store.getCurrentState().returnReasons];
	invalidForm = true;
	itemsToBeReturned: any[] = [];
	productStatusEnum = ProductStatusEnum;
	disableDownloadButton = false;
	translateService = inject(TranslateService);
	
	ngOnInit(): void {
		this._setTitle();
		this._getQueryParams();
	}
	
	checked_row(item: any, itemIndex: number, event?: any) {
		if (event) {
			if (item.return_reason === '-' || item.return_reason === undefined) {
				this.onSelectReasonModal(item, itemIndex);
				return;
			}
			event.stopPropagation();
		}
		const index = this.itemsToBeReturned.indexOf(item);
		this.items[itemIndex].invalidReason = !this.items[itemIndex].checked
			&& (!this.items[itemIndex].return_reason || this.items[itemIndex].return_reason === '-');
		if (index > -1) {
			this.itemsToBeReturned.splice(index, 1);
			this.checkFormValidity();
			this.items[itemIndex].checked = false;
			return;
		}
		this.items[itemIndex].checked = true;
		this.itemsToBeReturned.push(item);
		this.checkFormValidity();
	}
	
	checkFormValidity() {
		if (!this.itemsToBeReturned.length) {
			this.invalidForm = true;
			return;
		}
		this.invalidForm = this.itemsToBeReturned.some((item: { return_reason: string; damage_description: string }) => {
			return item.return_reason === '-' ||
				item.return_reason === undefined ||
				(item.return_reason === 'R8' && !item.damage_description) ||
				(item.return_reason === 'R8' && item.damage_description.length > 250 );
		});
	}
	
	onSelectReasonModal(item: any, index: number) {
		if (item.lineStatus === ProductStatusEnum.OPEN) {
			return;
		}
		const dialogRef = this._dialog.open(SelectReasonModalComponent, {data: this.items[index].return_reason});
		dialogRef.afterClosed().subscribe(result => {
			if (!result) {
				return;
			}
			this.items[index].return_reason = result;
			this.items[index].invalidDamageReason = this.items[index].return_reason === 'R8' && !this.items[index].damage_description;
			this.items[index].invalidDamageReasonisTooLong = this.items[index].damage_description && this.items[index].damage_description.length > 250;
			if (this.items[index].invalidReason || this.items[index].checked) {
				return;
			}
			this.items[index].invalidReason = result === '-';
			this.items[index].checked = !this.items[index].invalidReason;
			this.checked_row(item, index);
		});
	}
	
	cancel() {
		this._redirectToLogin();
	}
	
	addReturn() {
		if (!this.itemsToBeReturned.length) {
			return;
		}
		
		let header = this._createReturnRequestHeader();
		header.order_items = this._createReturnJSONAllOrderPositions(header);
		this._showReturnProcessLoader();
		
		header.subtotal = parseFloat(header.subtotal.toFixed(3));
		header.tax_amount = parseFloat((header.grand_total - header.subtotal).toFixed(3));
		
		this.request = header;
		
		this._apiService.add_request([header]).subscribe((response) => {
			this.general_data.return_no = response.returnNumber;
			this.itemsToBeReturned = [];
			
			setTimeout(() => {
				const printContents = document.getElementById('print-container');
				// @ts-ignore
				printContents.style.display = 'block';
				this._make_barcode(this.general_data.return_no);
				// @ts-ignore
				html2canvas(printContents).then(async (canvas) => {
					var canvasPNG = canvas.toDataURL('image/png');
					var pdf = new jsPDF();
					pdf.addImage(canvasPNG as string, 'PNG', 0, 0, 210, 297, undefined, 'FAST');
					
					var file = pdf.output();
					const mergedPdf = await PDFDocument.create();
					const pdfList = [btoa(file), response.label];
					for (let document of pdfList) {
						let pdfDoc1;
						try {
							pdfDoc1 = await PDFDocument.load(document);
						} catch (error: any) {
							this._dialog.open(ErrorNotificationModalComponent, {
								data: {
									title: this.translateService.instant('returnsViewPage.error'),
									message: error?.message ? error?.message : this.translateService.instant('returnsViewPage.labelGenerateError')
								}
							})
								.afterClosed()
								.pipe(take(1))
								.subscribe()
							return;
						}
						
						pdfDoc1 = await PDFDocument.load(document);
						const copiedPages = await mergedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
						copiedPages.forEach((page) => mergedPdf.addPage(page));
					}
					
					var pdfBytes = await mergedPdf.save();
					this._hideReturnProcessLoader();
					download(pdfBytes, 'Return Documents.pdf');
				});
				// @ts-ignore
				printContents.style.display = 'none';
				this._hideReturnProcessLoader();
				this.ngOnInit();
			}, 1000);
			
		}, (error: HttpErrorResponse) => {
			this._dialog.open(ErrorNotificationModalComponent, {
				data: {
					title: this.translateService.instant('returnsViewPage.error'),
					message: error?.error?.message ? error?.error?.message : this.translateService.instant('returnsViewPage.labelGenerateError')
				}
			})
			this._hideReturnProcessLoader();
		});
		return;
	}
	
	your_return_view(event: any) {
		let return_no = event.target.getAttribute('data-return-no');
		let param_email = this._buildEmailParam();
		let param_order = this._buildOrderNumberParam();
		this._router.navigateByUrl(`your_return/${this._route.snapshot.params.brand}?lang=${this.translateService.currentLang}&email=${param_email}&order=${param_order}&formonly=${this.form}&return_no=${return_no}`);
	}
	
	onSelect(event: any, index: number): void {
		this.items[index].return_reason = event.target.value;
		this.items[index].invalidReason = this.items[index].checked && event.target.value === '-';
		this.items[index].invalidDamageReason = this.items[index].return_reason === 'R8' && !this.items[index].damage_description;
		this.items[index].invalidDamageReasonisTooLong = this.items[index].damage_description && this.items[index].damage_description.length > 250;
		this.checkFormValidity();
	}
	
	checkDamageReason(index: number): void {
		this.items[index].invalidDamageReason = this.items[index].return_reason === 'R8' && !this.items[index].damage_description;
		this.items[index].invalidDamageReasonisTooLong = this.items[index].damage_description && this.items[index].damage_description.length > 250;
		this.checkFormValidity();
	}
	
	private _make_barcode(barcode: number) {
		JsBarcode('.barcode', barcode, {
			format: 'CODE128',
			flat: true,
			height: 60,
			width: 2.4,
			displayValue: false
		});
	}
	
	private _showReturnProcessLoader() {
		this.disableDownloadButton = true;
		(document.getElementById('process_loading') as HTMLElement).classList.remove('no-display');
	}
	
	private _hideReturnProcessLoader() {
		this.disableDownloadButton = false;
		this.checkFormValidity();
		(document.getElementById('process_loading') as HTMLElement).classList.add('no-display');
	}
	
	private _createReturnRequestHeader() {
		return {
			cardcodefire: '',
			return_num: '',
			order_num: this.order_header.order_num,
			date: `${this.items[0]?.order_date} 08:00`,
			currency: this.order_header.currency,
			language: 'en',
			grand_total: 0,
			subtotal: 0,
			tax_amount: 0,
			discount_amount: 0,
			discount_percent: 0,
			total_paid: 0,
			paypaltransId: this.order_header.paypaltransId,
			comment: '',
			source: this._store.getCurrentState().source,
			addresses: [
				this._getClientBillingAsObject(),
				this._getClientShipingAsObject()
			],
			order_items: []
		};
	}
	
	private _getClientBillingAsObject() {
		return {
			address_type: 'billing',
			company: this.client.company,
			salutation: this.client.salutation,
			lastname: this.client.lastname,
			firstname: this.client.firstname,
			email: this.client.email,
			telephone: this.client.telephone,
			country: this.client.billing_country,
			city: this.client.billing_city,
			street: this.client.billing_street,
			number: this.client.billing_number,
			postcode: this.client.billing_postcode,
			clientNumber: ''
		};
	}
	
	private _getClientShipingAsObject() {
		return {
			address_type: 'shiping',
			company: this.client.company,
			salutation: this.client.salutation,
			lastname: this.client.lastname,
			firstname: this.client.firstname,
			email: this.client.email,
			telephone: this.client.telephone,
			country: this.client.shiping_country,
			city: this.client.shiping_city,
			street: this.client.shiping_street,
			number: this.client.shiping_number,
			postcode: this.client.shiping_postcode,
			packingstation: ''
		};
	}
	
	private _createReturnJSONAllOrderPositions(header: any) {
		let order_positions: any = [];
		
		for (let i = 0; i < this.itemsToBeReturned.length; i++) {
			order_positions.push(this._apiService.createReturnRequestOrderItems(this.itemsToBeReturned[i], this.client));
			header = this._apiService.updateReturnRequestHeaderData(header, this.itemsToBeReturned[i]);
		}
		return order_positions;
	}
	
	private _buildOrderNumberParam() {
		return btoa(this.order_num);
	}
	
	private _buildEmailParam() {
		return btoa(this.email);
	}
	
	private _formatDateAsGermanDateFormat(date: any) {
		return new Date(date).toLocaleDateString('en-GB').split('/').join('.');
	}
	
	private _getData() {
		this._apiService.read_data(this.email, this.order_num).subscribe(
			(data) => {
				if (!data) {
					this.noOrderItemAction();
					return;
				}
				this._setItems(data);
				this._setClient(data);
				this._setOrderHeader(data);
				this._setTotalPaid(data);
				this._dismissLoader();
				this._store.update({source: data.source});
				window.sessionStorage.setItem('source', data.source);
				this.general_data = data;
				this.order_header.order_date = this.order_header.order_date.split('-').join('.');
			},
			(error) => {
				this._dismissLoader();
				this._redirectToLogin();
			}
		);
	}
	
	private _setItems(obj: any) {
		this.items = obj.positions;
	}
	
	private _setClient(obj: any) {
		this.client = obj.client;
	}
	
	private _setOrderHeader(obj: any) {
		this.order_header = {
			order_num: obj.order_num,
			order_status: obj.order_status,
			item_count: obj.positions.length,
			order_date: obj.order_date,
			currency: obj.currency,
			total_paid: '',
			paypaltransId: obj.paypaltransId
		};
	}
	
	private _setTotalPaid(obj: any) {
		let total_paid = 0;
		for (let i = 0; i < obj.positions.length; i++) {
			total_paid += obj.positions[i].price_with_discounts;
		}
		this.order_header.total_paid = (Math.round(total_paid * 100) / 100).toString();
	}
	
	private _dismissLoader() {
		(document.getElementById('page_loader') as HTMLElement).classList.add('no-display');
	}
	
	private _redirectToLogin() {
		this._router.navigateByUrl(`/check-order/${this._route.snapshot.params.brand}?lang=${this.translateService.currentLang}&formonly=${this.form}`);
	}
	
	private noOrderItemAction() {
		$('#no_order_item').modal('show');
		this._dismissLoader();
	}
	
	private _getReturns(orderNumber: string) {
		this._apiService.get_returns(orderNumber).subscribe(
			(data) => {
				if (data.length > 0) {
					this.returns = data;
					for (let i = 0; i < this.returns.length; i++) {
						this.returns[i].order_date = this._formatDateAsGermanDateFormat(this.returns[i].order_date);
					}
					this.returns = this.returns.sort((a: { return_no: number; }, b: {
						return_no: number;
					}) => b.return_no - a.return_no);
				}
			},
			() => {
			}
		);
	}
	
	private _getQueryParams() {
		this._route.queryParams.subscribe((params) => {
			if (!params) {
				return;
			}
			this.email = decodeURIComponent(atob(params.email));
			this.order_num = decodeURIComponent(atob(params.order));
			this._getData();
			this._getReturns(this.order_num);
		});
	}
	
	private _setTitle() {
		this._titleService.setTitle('AstorMueller | Order items');
	}
	
}
