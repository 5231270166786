<!-- Modal order has expired Expired -->
<div class="modal-body">
  <div class="modal-dialog"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>{{message}}</strong><br><br>
        <strong>{{'loginPage.no_order_body2' | translate}}</strong><br><br>
        <a href="mailto:{{customerSupportEmail | translate}}">
          {{customerSupportEmail | translate}}
        </a>{{customerSupportPhone | translate}}<br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</div>