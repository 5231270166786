import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {ReturnsStore} from "./core/store/returns.state";
import {TranslateService} from "@ngx-translate/core";

declare const $: any;

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	form = this.store.getCurrentState().hasForm;
	headers = new HttpHeaders();
	
	constructor(private httpClient: HttpClient,
							private store: ReturnsStore,
							private router: Router,
							private _translateService: TranslateService) {
	}
	
	login(email: any, order_num: any, brand?: string): any {
		this.headers = this.headers.append('Content-Type', 'application/json');
		this.headers = this.headers.append(
			'Authorization',
			'Basic ' + btoa(`${environment.user}:${environment.pass}`)
		);
		
		let params = new HttpParams();
		params = params.append('email', email);
		params = params.append('order', order_num);
		params = params.append('last_days', 60);
		
		this.httpClient.get<any>(`${environment.api_url}/login`, {
			headers: this.headers,
			params
		}).subscribe({
			next: (data) => {
				if (data.message.toLowerCase() == 'success') {
					localStorage.setItem('token', data.token);
					const lang = this._translateService.currentLang || 'en';
					this.router.navigateByUrl(`orders/${brand}?email=${btoa(encodeURIComponent(email))}&order=${btoa(encodeURIComponent(order_num))}&formonly=${this.form}&lang=${lang}`)
						.then();
					return;
				}
				$('#message_modal').modal('show');
			},
			error: () => {
				$('#error_modal').modal('show');
			}
		});
	}
}
