import {APP_INITIALIZER, enableProdMode, importProvidersFrom, Injector, LOCALE_ID} from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OrderViewComponent } from './app/components/order-view/order-view.component';
import {provideRouter, withViewTransitions} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthInterceptor } from './app/auth.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {LOCATION_INITIALIZED} from "@angular/common";

if (environment.production) {
    enableProdMode();
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}

export function appInitializerFactory(translate: TranslateService, injector: Injector): object {
    return async (): Promise<void> => {
        await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');
        try {
            // for now, we only need default language to be used.(this might change in the future)
            await translate.use(translate.defaultLang).toPromise();
        } catch (err) {
            console.error(err);
        }
    };
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, MatDialogModule, ReactiveFormsModule, FormsModule,
          TranslateModule.forRoot({
              defaultLanguage: 'en',
              loader: {
                  provide: TranslateLoader,
                  useFactory: createTranslateLoader,
                  deps: [HttpClient]
              }
          })
        ),
        { provide: LOCALE_ID, useValue: 'en-GB' },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: MatDialogRef,
            useValue: {}
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter([
            { path: 'order', component: OrderViewComponent }
        ], withViewTransitions())
    ]
})
  .catch(err => console.error(err));
