import {Component, inject, OnInit} from '@angular/core';
import {AuthService} from '../../auth.service';
import {UntypedFormGroup, Validators, ReactiveFormsModule, FormBuilder} from '@angular/forms';
import {BrandEnum} from '../../core/enums/brand.enum';
import {environment} from '../../../environments/environment';
import {ReturnsStore} from "../../core/store/returns.state";
import {BrandSwitcherComponent} from '../brand-switcher/brand-switcher.component';
import {NgIf} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	standalone: true,
	imports: [NgIf, ReactiveFormsModule, BrandSwitcherComponent, TranslateModule]
})
export class LoginComponent implements OnInit {
	private _store = inject(ReturnsStore);
	private _auth = inject(AuthService);
	private _fb = inject(FormBuilder);
	
	loginForm: UntypedFormGroup | undefined;
	brand: string = this._store.getCurrentState().brand;
	brandEnum = BrandEnum;
	customerSupportEmail: string = this._store.getCurrentState().customerSupportEmail;
	customerSupportPhone: string = this._store.getCurrentState().customerSupportPhone;
	orderNumberPlaceholder: string = this._store.getCurrentState().orderNumberPlaceholder;
	isInDev = !environment.production;
	
	ngOnInit(): void {
		this._buildForm();
	}
	
	/**
	 * Method to check order data
	 */
	appLogin(): void {
		this._auth.login(
			this.loginForm!.value.email.replace(/\s/g, ""),
			this.loginForm!.value.order_num.replace(/\s/g, ""),
			this._store.getCurrentState().brand);
	}
	
	/**
	 * Method to build form
	 * @private
	 */
	private _buildForm(): void {
		this.loginForm = this._fb.group({
			email: [null, [Validators.required, Validators.email]],
			order_num: [this._store.getCurrentState().orderNumberPrefix]
		})
		this._getOrderNumberValidators();
	}
	
	/**
	 * Method to check order number validations
	 * @private
	 */
	private _getOrderNumberValidators(): void {
		let validators = [Validators.required];
		switch (this.brand) {
			case this.brandEnum.Bugatti:
				validators.push(Validators.pattern(/^\s*B-(\d{4})\s*$|^\s*B-(\d{5})\s*$|^\s*B-(\d{6})\s*$/));
				break;
			case this.brandEnum.Bagatt:
				validators.push(Validators.pattern(/^\s*BA-(\d{4})\s*$|^\s*BA-(\d{5})\s*|^\s*BA-(\d{6})\s*$/));
				break;
			case this.brandEnum.daniel_hechter:
				validators.push(Validators.pattern(/^\s*DH-(\d{4})\s*$|^\s*DH-(\d{5})\s*$/));
				break;
			default:
				break;
		}
		this.loginForm!.controls.order_num.setValidators(validators);
		this.loginForm!.controls.order_num.updateValueAndValidity();
	}
	
}