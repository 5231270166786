import {Component, Input, OnChanges} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {CountryEnum} from "../../../core/enums/country.enum";

@Component({
    selector: 'app-print-label',
    templateUrl: './print-label.component.html',
    styleUrls: ['./print-label.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, TranslateModule]
})
export class PrintLabelComponent implements OnChanges {
	@Input() productBrand: string = '';
	@Input() general_data: any;
	@Input() client: any;
	@Input() items: any;
	
	returnAddresses: string[] = [];
	
	private _nonSwissAddress: string [] = ['AstorMueller AG c/o Dirks Distribution', 'Felix-Wankel-Straße 900', '84570 Polling-Weiding', 'Deutschland'];
	private _swissAddress: string [] = ['AstorMueller AG', 'c/o SwissCommerce', 'Chasseralstrasse 3', '4900 Langenthal', 'Switzerland'];
	
	ngOnChanges(): void {
		this.returnAddresses = this.client.billing_country === CountryEnum.SWITZERLAND ? this._swissAddress : this._nonSwissAddress;
	}
	
}
