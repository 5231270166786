import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {Location, NgFor, NgIf, UpperCasePipe, DecimalPipe} from '@angular/common';
import {Title} from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import {PDFDocument} from 'pdf-lib';
import download from "downloadjs";
import {ReturnsStore} from "../../core/store/returns.state";
import {PrintLabelComponent} from './print-label/print-label.component';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ErrorNotificationModalComponent} from "../error-notification-modal/error-notification-modal.component";
import {take} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

declare const JsBarcode: any;

@Component({
	selector: 'app-your-return',
	templateUrl: './your-return.component.html',
	styleUrls: ['./your-return.component.scss'],
	standalone: true,
	imports: [NgFor, NgIf, PrintLabelComponent, UpperCasePipe, DecimalPipe, TranslateModule]
})
export class YourReturnComponent implements OnInit {
	private _apiService = inject(ApiService);
	private _route = inject(ActivatedRoute);
	private _router = inject(Router);
	private _location = inject(Location);
	private _titleService = inject(Title);
	private _store = inject(ReturnsStore);
	private _dialogRef = inject(MatDialog);
	
	items: any = [];
	client: any = {};
	productBrand = this._store.getCurrentState().brand;
	general_data: any = {};
	return_no: any;
	form = this._store.getCurrentState().hasForm;
	email: string = '';
	order_num: string = '';
	pdf = '';
	urlData = [];
	translateService = inject(TranslateService);
	
	ngOnInit(): void {
		this._setPageTitle();
		this._getQueryParams();
		this._makeInit();
	}
	
	input_change(element: any) {
		this._updatePDFOnInputChangeOnFirstAndLastname(element);
		this._updatePDFOnInputChangeOnPostCode(element);
		this._onOtherInputChanges(element);
	}
	
	back() {
		let param_email = btoa(this.email);
		let param_order = btoa(this.order_num);
		this._router.navigateByUrl(
			`orders/${this._store.getCurrentState().brand}?lang=${this.translateService.currentLang}&email=${param_email}&order=${param_order}&formonly=${this.form}`
		).then();
	}
	
	downloadLabels() {
		this._enablePageLoader();
		const printContents = document.getElementById('print-container')!;
		// @ts-ignore
		printContents.style.display = 'block';
		// @ts-ignore
		html2canvas(printContents).then(async (canvas) => {
			const canvasPNG = canvas.toDataURL('image/png', 1);
			const pdf = new jsPDF();
			pdf.addImage(canvasPNG as string, 'PNG', 0, 0, 210, 297, undefined, 'FAST');
			
			const file = pdf.output();
			const mergedPdf = await PDFDocument.create();
			const pdfList = [btoa(file), this.pdf];
			for (let document of pdfList) {
				let pdfDoc1;
				try {
					pdfDoc1 = await PDFDocument.load(document);
				} catch (e) {
					this._dialogRef.open(ErrorNotificationModalComponent, {
						data: {
							title: this.translateService.instant('returnsViewPage.error'),
							message: this.translateService.instant('returnsViewPage.labelGenerateError')
						}
					})
						.afterClosed()
						.pipe(take(1))
						.subscribe()
					return;
				}
				
				pdfDoc1 = await PDFDocument.load(document);
				const copiedPages = await mergedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
				copiedPages.forEach((page) => mergedPdf.addPage(page));
			}
			
			const pdfBytes = await mergedPdf.save();
			download(pdfBytes, 'Return Documents.pdf');
			this._dismissPageLoader();
		});
		printContents.style.display = 'none';
		this._dismissPageLoader();
	}
	
	private _setPageTitle() {
		this._titleService.setTitle('AstorMueller | Your order');
	}
	
	private _getQueryParams() {
		this._route.queryParams.subscribe((params) => {
			this.email = atob(params.email);
			this.order_num = atob(params.order);
			this.return_no = params.return_no;
			this.urlData = params?.data ? JSON.parse(atob(params.data)) : null;
		});
	}
	
	private _isAlreadyReturned() {
		return this.return_no != null;
	}
	
	private _showPageWithAlreadyMadeReturn() {
		this._showYourReturnDocumentContent();
		this._deactivateInputs();
		this._make_barcode(this.return_no);
	}
	
	private _addReturnNoToPDF() {
		this.general_data.return_no = this.return_no;
	}
	
	private _deactivateInputs() {
		let allInputs = (document.querySelectorAll('input'));
		for (let i = 0; i < allInputs.length; i++) {
			allInputs[i].disabled = true;
		}
	}
	
	private _showYourReturnDocumentContent() {
		this._apiService.get_your_return_document(this.email, this.order_num, this.return_no).subscribe(
			(data) => {
				this._setGlobalVariablesValue(data);
				this._makeCalculations(data.positions, data.positions);
				this._dismissPageLoader();
				this.pdf = data.label;
				this._addReturnNoToPDF();
			},
			(error: HttpErrorResponse) => {
				this._onInitError(error);
			}
		);
	}
	
	private _makeInit() {
		if (this._isAlreadyReturned()) {
			this._showPageWithAlreadyMadeReturn();
			return;
		}
		let data: any;
		this._route.queryParams.subscribe((params) => {
			data = atob(params.data);
		});
		
		let items = '';
		let json_data = JSON.parse(data);
		for (let i = 0; i < json_data.length; i++) {
			items = items + `${json_data[i].article_code},`;
		}
		
		this._apiService.your_return(this.email, this.order_num, items).subscribe(
			(data) => {
				this._redirectIfJsonResponseIsNotValid(data);
				this._setGlobalVariablesValue(data);
				this._makeCalculations(json_data, data);
				this._setTransportCostIfNeeded();
				this._dismissPageLoader();
			},
			(error: HttpErrorResponse) => {
				this._onInitError(error);
			}
		);
	}
	
	private _makeCalculations(jsonDOM: any, jsonAPI: any) {
		for (let i = 0; i < this.items.length; i++) {
			for (let j = 0; j < jsonDOM.length; j++) {
				if (jsonDOM[j].article_code === this.items[i].article_code) {
					this._verifyIfQuantityWasModifiedFromDOM(jsonDOM[j].quantity, this.items[i].quantity);
					if (jsonDOM[j].quantity < this.items[i].quantity) {
						jsonAPI.transport_refund = false;
					}
					this._calculateTotalPrice(j);
					this.items[i].quantity = jsonDOM[j].quantity;
					this._calculatePriceWithDiscountsPerItem(jsonDOM, i, j);
				}
			}
		}
	}
	
	private _dismissPageLoader() {
		(document.getElementById('page_loader') as HTMLElement).classList.add('no-display');
	}
	
	private _enablePageLoader() {
		(document.getElementById('page_loader') as HTMLElement).classList.remove('no-display');
	}
	
	private _setGlobalVariablesValue(JSON: any) {
		this.items = JSON.positions;
		this.general_data = JSON;
		this.client = JSON.client;
	}
	
	private _redirectIfJsonResponseIsNotValid(JSON: any) {
		if (JSON.length < 1 || JSON.positions.length < 1) {
			this._router.navigateByUrl(`?lang=${this.translateService.currentLang}&formonly=${this.form}`).then();
		}
	}
	
	private _calculatePriceWithDiscountsPerItem(jsonDOM: any, i: any, j: any) {
		this.items[i].price_with_discounts = jsonDOM[j].quantity * this.items[i].price_with_discounts;
	}
	
	private _calculateTotalPrice(index: any) {
		this.general_data.total_price = (this.general_data.total_price ?? 0) + this.items[index].price_with_discounts;
		this.general_data.total_price = Math.round(this.general_data.total_price * 1000) / 1000;
	}
	
	private _verifyIfQuantityWasModifiedFromDOM(fromDOM: any, fromAPI: any) {
		if (fromDOM > fromAPI) {
			alert('Something went wrong, try again later');
			this._location.back();
		}
	}
	
	private _setTransportCostIfNeeded() {
		if (this._hasTransportCost()) {
			this.general_data.transport_cost = `${this.translateService.instant('returnsViewPage.transport_cost')}: ` + this.items[0].transport_cost.toString() + ' €';
			this.general_data.total_price = (this.general_data.total_price ?? 0) + this.items[0].transport_cost;
			this.general_data.total_price = this._roundThreeDigits(this.general_data.total_price);
		}
	}
	
	private _onInitError(error: HttpErrorResponse) {
		this._dialogRef.open(ErrorNotificationModalComponent, {
			data: {
				title: this.translateService.instant('returnsViewPage.error'),
				message: error?.error?.message ? error?.error?.message : this.translateService.instant('returnsViewPage.labelGenerateError')
			}
		})
		this._dismissPageLoader();
		if(error.error && error.error?.message && error?.error?.message.indexOf('DHL') > -1) {
			return;
		}
		this._router.navigateByUrl(
			`?lang=${this.translateService.currentLang}&formonly=${this.form}`
		).then();
	}
	
	private _updatePDFOnInputChangeOnFirstAndLastname(element: any) {
		if (element.target.dataset.name == 'first_nd_lastname') {
			let full_name = element.target.value.trim().split(' ');
			let first_name = '';
			let last_name = '';
			for (let i = 0; i < full_name.length; i++) {
				if (i < full_name.length - 1) {
					first_name += ' ' + full_name[i];
				} else {
					last_name = full_name[i];
				}
			}
			this.client.firstname = first_name;
			this.client.lastname = last_name;
		}
	}
	
	private _make_barcode(barcode: number) {
		JsBarcode('.barcode', barcode, {
			format: 'CODE128',
			flat: true,
			height: 60,
			width: 2.4,
			displayValue: false
		});
	}
	
	private _onOtherInputChanges(element: any) {
		this.client[element.target.dataset.name] = element.target.value;
	}
	
	private _updatePDFOnInputChangeOnPostCode(element: any) {
		if (element.target.dataset.name == 'postcode') {
			const country_code = ((document.getElementById('country') as HTMLInputElement).value);
			
			if (country_code.toUpperCase() == 'AT' && element.target.value.length <= 4) {
				this.client[element.target.dataset.name] = element.target.value;
				this.general_data = this.client;
			} else if (country_code.toUpperCase() == 'AT') {
				((document.getElementById('postcode') as HTMLInputElement).value) = element.target.value.substring(0, 4);
			}
		}
	}
	
	private _roundThreeDigits(value: number) {
		return value.toFixed(3);
	}
	
	private _hasTransportCost() {
		return this.general_data.transport_refund == true && this.items[0].transport_cost > 0;
	}
	
}
