<section #image
         id="image"
         [class.center]="brandEnum.Elwin === brand">
  <div class="text-container row"
       [class.bagatt]="brandEnum.Bagatt === brand">
    <div class="col">
      <h1 id="heading1">{{'loginPage.title' | translate}}</h1>
      <p class="paragraph1">{{'loginPage.subtitle' | translate}}</p>
      <p class="paragraph1">{{'loginPage.subtitle1' | translate}}</p>
    </div>
  </div>
</section>
