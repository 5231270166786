<nav id="navbar_am"
     class="navbar navbar-expand-lg navbar-dark">
  <div id="navbarNav">
    <ul class="navbar-nav">
      <a class="navbar-brand"
         (click)="navigate()">
        <img src="assets/img/{{iconSrc}}"
             width="20"
             height="20"
             class="navbar-brand-img"
             alt="{{brand}}">
      </a>
      <li class="nav-item">
        <span class="nav-link">{{'loginPage.official' | translate}} {{brandNameToDisplay | uppercase}} {{'loginPage.returnPortal' | translate}}</span>
      </li>
      <li class="nav-item small-hide"
          *ngIf="brand === brandEnum.Bugatti">
          <span class="nav-link"
                href="#">
            <i class="fas fa-check"></i>
            {{'loginPage.free_shipping' | translate}}
          </span>
      </li>
      <li class="nav-item">
              <span class="nav-link"
                    href="#">
                <i class="fas fa-check"></i>
                {{'loginPage.returnPolicy' | translate}}
              </span>
      </li>
      <li class="nav-item small-hide"
          *ngIf="brand !== brandEnum.Bagatt">
          <span class="nav-link"
                href="#">
            <i class="fas fa-undo-alt"></i>
            {{'loginPage.make_a_return' | translate}}
          </span>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           href="tel:{{brandNumber}}">
          <i class="fas fa-check"></i>
          {{'loginPage.contact' | translate}} {{brandNumber}} {{'loginPage.contactEmail' | translate}} {{brandEmail}}
        </a>
        <select name="languageSwitcher"
                class="language-selector"
                [ngModel]="selectedLanguage"
                (change)="onLanguageSwitch($event)">
          <option *ngFor="let lang of languages"
                  [value]="lang">
            {{lang | uppercase}}
          </option>
        </select>
      </li>
    </ul>
  </div>
</nav>
<section id="brand">
  <img src="assets/img/{{bannerSrc}}"
       (click)="navigate(true)"
       [class.hechter]="brand === 'Daniel Hechter'"
       alt="{{brand}}">
</section>
