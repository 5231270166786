import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {inject, Injectable} from "@angular/core";
import {ReturnsStore} from "./core/store/returns.state";

@Injectable({
	providedIn: 'root'
})
export class LanguageGuard {
	private _router = inject(Router);
	private _store = inject(ReturnsStore);
	private _languages = this._store.getCurrentState().languages;
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		
		const lang = route.queryParams.lang;
		let queryParams = '';
		
		for (const property in route.queryParams) {
			if (property === 'lang') continue;
			queryParams += `&${property}=${route.queryParams[property]}`;
		}
		
		const builtUrl = state.url.split('?')[0] + `?lang=en` + queryParams;
		
		if (this._languages.indexOf(lang) > -1 && sessionStorage.getItem('lang')) {
			return true;
		} else {
			this._router.navigateByUrl(builtUrl).then();
			sessionStorage.setItem('lang', 'en');
			return true;
		}
	}
}