<div id="page_loader" class="page-loader">
  <div class="spinner"></div>
</div>
<div class="fluid-container list-returns-container no-gutters">
  <div class="row hide-on-mobile">
    <div class="col">
      <h3 id="your_return_order_text">{{'returnsViewPage.your_return_order_text' | translate}}</h3><br>
      <p class="m-t-20 article-text">{{'returnsViewPage.articles_text' | translate}}</p>
    </div>
  </div>
  <div class="divider hide-on-mobile"></div>
  <div class="row list-header hide-on-mobile">
    <div class="col"><span id="order_no_text">{{'returnsViewPage.order_num' | translate}}: </span> <span
        id="order">{{general_data.order_num}}</span></div>
    <div class="col text-right"><span id="order_date_text">{{'returnsViewPage.order_date' | translate}}:</span> <span
        id="order_date"> {{general_data.order_date}}</span>
    </div>
  </div>
  
  <div id="data_container"
       class="hide-on-mobile">
    <div class="row list-returns" *ngFor="let item of items">
      <div class="col">
        <div class="item">
          <img class="sku-image" src="{{item.img}}" width="160" height="120">
          <div class="sku-detail">
            <span class="sku-group">{{item.article_group}}</span>
            <span class="sku-color">{{item.article_color}}</span>
            <span class="sku-size">{{'returnsViewPage.size' | translate}} {{item.article_size}}</span>
            <span class="sku-value">{{item.article_code}}</span>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <p class="p-t-30">
          <strong>{{'returnsViewPage.price' | translate}} {{item.selling_price | number : '1.2-2'}} {{general_data.currency}}</strong></p>
      </div>
      <div class="col">
        <div class="dropdown-btn">
          <span *ngIf="'returnsViewPage.' + item.return_reason | translate"
                class="font-weight-bold">{{'returnsViewPage.' + item.return_reason | translate}}
              <span *ngIf="item.damage_description || item.return_reason_description">:</span>
          </span>
          <div *ngIf="item.damage_description"
               class="mt-2 damage-description break-word">
            {{item.damage_description}}
          </div>
          
          <div *ngIf="item.return_reason_description"
               class="mt-2 damage-description break-word">
            {{item.return_reason_description}}
          </div>

        </div>
      </div>
    </div>
  </div>
  
  <div class="divider hide-on-mobile"></div>
  <strong>{{general_data.transport_cost}}</strong>
  <div class="container text-center hide-on-mobile">
    <p id="total_price" class="p-t-15">
      <strong>{{'returnsViewPage.total_price' | translate}} {{general_data.total_price | number : '1.2-2'}} {{general_data.currency}}</strong>
    </p>
  </div>
  <div class="divider hide-on-mobile"></div>
  
  <!--  Mobile view  -->
  <div class="row side-menu mobile-view">
    <div class="sidebar-content">
      <span class="sidebar-title" id="my_account">{{'returnsViewPage.my_account' | translate}}</span>
      <p id="sidebar-text-name" class="sidebar-text">{{client.firstname}} {{client.lastname}}</p>
    </div>
    <div class="sidebar-content">
      <span class="sidebar-title"></span>
      <p id="sidebar-text-address"
         class="sidebar-text">{{client.billing_street}} {{client.billing_number}}</p>
      <p id="sidebar-text-zip" class="sidebar-text">{{client.billing_postcode}}</p>
      <p id="sidebar-text-city" class="sidebar-text">{{client.billing_city}}</p>
    </div>
  </div>
  
  <!--      Mobile view of list      -->
  <div class="mobile-view">
    <h2 id="your_order_text">{{'returnsViewPage.your_order' | translate}}</h2>
    
    <div class="order-summary">
      <div>
        <div class="title">
          {{'returnsViewPage.order_num' | translate}}
        </div>
        <div class="info">
          {{general_data.order_num}}
        </div>
      </div>
      <div>
        <div class="title">
          {{'returnsViewPage.status' | translate}}
        </div>
        <div class="info">
          {{general_data.order_status}}
        </div>
      </div>
      <div>
        <div class="title">
          {{'returnsViewPage.order_date' | translate}}
        </div>
        <div class="info">
          {{general_data.order_date}}
        </div>
      </div>
      <div>
        <div class="title">
          {{'returnsViewPage.total_value' | translate}}
        </div>
        <div class="info">
          {{general_data.total_paid | number : '1.2-2'}} {{general_data.currency}}
        </div>
      </div>
    </div>
    
    <div class="order-list">
      <div class="title">
        {{'returnsViewPage.items' | translate | uppercase}}
      </div>
      <ng-container *ngFor="let orderItem of items; let i = index">
        <div class="mobile-card">
          <div class="mobile-details">
            <div class="mobile-body">
              <img class="sku-image"
                   src="{{orderItem.img}}"
                   height="120">
              <div class="card-info">
                <div>
                  {{orderItem.article_code}}
                </div>
                <div class="item-info">
                  <div>
                    <div>
                      {{orderItem.article_color}}
                    </div>
                    <div>{{'returnsViewPage.size' | translate}} {{orderItem.article_size}}</div>
                  </div>
                  <div>
                    {{orderItem.selling_price | number : '1.2-2'}} {{general_data.currency}}
                  </div>
                </div>
              </div>
            </div>
            <button class="dropdown-btn">
              <span *ngIf="'returnsViewPage.' + orderItem.return_reason | translate"
                    class="font-weight-bold">{{'returnsViewPage.' + orderItem.return_reason | translate}}
                <span *ngIf="orderItem.damage_description || orderItem.return_reason_description">:</span>
              </span>
              
              <div *ngIf="orderItem.damage_description"
                    class="break-word">
                {{orderItem.damage_description}}
              </div>
              
              <div *ngIf="orderItem.return_reason_description"
                   class="break-word">
                {{orderItem.return_reason_description}}
              </div>
              
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  
  <!-- personal details -->
  <div class="row p-t-20 user-info">
    <div class="col">
      <h2 class="p-t-20 article-text" id="personal_details_text">{{'returnsViewPage.personal_details' | translate}}</h2>
    </div>
  </div>
  <div class="divider hide-on-mobile"></div>
  <div class="row p-t-20 user-info">
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group ">
        <label for="name" id="first_nd_lastname_text">{{'returnsViewPage.first_nd_lastname' | translate}}</label>
        <input type="text" class="form-control form-item-light" value="{{client.firstname}} {{client.lastname}}"
               (keyup)="input_change($event)" data-name="first_nd_lastname"
               id="name" placeholder="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="email" id="email_text">{{'returnsViewPage.email' | translate}}</label>
        <input type="email" value="{{client.email}}" (keyup)="input_change($event)" data-name="email"
               class="form-control form-item-light" id="email" placeholder="email@gmail.com">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="phone" id="phone_number_text">{{'returnsViewPage.phone_number' | translate}}</label>
        <input type="text" value="{{client.telephone}}" (keyup)="input_change($event)" data-name="telephone"
               class="form-control form-item-light" id="phone">
      </div>
    </div>
  </div>
  
  
  <!-- address -->
  <div class="row user-info">
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <h2 class="p-t-20 article-text" id="address_text">{{'returnsViewPage.address' | translate}}</h2>
    </div>
  </div>
  <div class="divider hide-on-mobile"></div>
  <div class="row p-t-20 user-info">
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="street" id="street_address_text">{{'returnsViewPage.street_address' | translate}}</label>
        <input type="text" value="{{client.shiping_street}}" (keyup)="input_change($event)"
               data-name="billing_street" class="form-control form-item-light" id="street"
               placeholder="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="street_no" id="street_number_text">{{'returnsViewPage.street_number' | translate}}</label>
        <input type="text" value="{{client.shiping_number}}" (keyup)="input_change($event)"
               data-name="billing_number" class="form-control form-item-light" id="street_no"
               placeholder="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="house" id="building_house_text">{{'returnsViewPage.building_house' | translate}}</label>
        <input type="text" class="form-control form-item-light" id="house" placeholder="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="country" id="country_text">{{'returnsViewPage.country' | translate}}</label>
        <input type="text" value="{{client.shiping_country}}" (keyup)="input_change($event)"
               data-name="billing_country" class="form-control form-item-light" disabled id="country"
               placeholder="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="city" id="city_town_text">{{'returnsViewPage.city_town' | translate}}</label>
        <input type="text" value="{{client.shiping_city}}" (keyup)="input_change($event)"
               data-name="billing_city" class="form-control form-item-light" id="city" placeholder="">
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label for="postcode" id="postcode_text">{{'returnsViewPage.postcode' | translate}}</label>
        <input type="number" value="{{client.shiping_postcode}}" (keyup)="input_change($event)"
               data-name="billing_postcode"
               class="form-control form-item-light" id="postcode" placeholder="">
      </div>
    </div>
  </div>
  
  <div class="divider m-t-10 m-b-20 hide-on-mobile"></div>
  
  <div class="row p-t-20 p-b-40 hide-on-mobile">
    <div class="col-2 m-t-10">
      <button type="button" id="next-btn" (click)="back();"
              class="btn capital fs-14 no-letter-spacing float-left">
        {{'returnsViewPage.button_back' | translate}}</button>
    </div>
    <div class="col-10">
      <div class="row float-right ">
        <div class="col m-t-10">
          <button type="button" id="print-btn"
                  (click)="downloadLabels()"
                  class="btn original-text fs-14 w-240 no-letter-spacing float-right">
            {{'returnsViewPage.button_download_labels' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="button-mobile">
    <button type="button"
            (click)="back()"
            class="btn">{{'returnsViewPage.button_back' | translate}}</button>
    <div class="download-btn">
      <button type="button"
              (click)="downloadLabels()"
              class="btn">{{'returnsViewPage.button_download_labels' | translate}}</button>
    </div>
  </div>
</div>


<!-- print preview -->
<app-print-label [productBrand]="productBrand"
                 [general_data]="general_data"
                 [client]="client"
                 [items]="items"></app-print-label>

<!-- error not selected for all skus return reason -->
<!-- Modal -->
<div class="modal fade" id="return_reason_error" tabindex="-1" role="dialog" aria-labelledby="return_reason_errorLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="return_reason_errorLabel">{{'returnsViewPage.return_reason_errorLabel' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong id="return_reason_error_text">{{'returnsViewPage.return_reason_error_text' | translate}}</strong><br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" id="btn-close"
                data-dismiss="modal">{{'returnsViewPage.close' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div id="process_loading" class="loading2 no-display">
  <div class="loading">
    <div style="text-align: center">
      <h3 class="threeD-text">
        <div>
          {{'returnsViewPage.process_request_1' | translate}}
        </div>
        <div>
          {{'returnsViewPage.process_request_2' | translate}}
        </div>
      </h3>
      <div style="text-align: center">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</div>
