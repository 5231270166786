import {Component, inject} from '@angular/core';
import {ActivatedRoute, ActivationStart, Router, RouterOutlet} from '@angular/router';
import {BrandEnum} from './core/enums/brand.enum';
import {Title} from '@angular/platform-browser';
import {ReturnsStore} from "./core/store/returns.state";
import {LoginImageComponent} from './components/login-image/login-image.component';
import {HeaderComponent} from './components/header/header.component';
import {NgIf} from '@angular/common';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	standalone: true,
	imports: [NgIf, HeaderComponent, LoginImageComponent, RouterOutlet, TranslateModule]
})

export class AppComponent {
	private _translateService = inject(TranslateService);
	
	hasForm: boolean = true;
	brand: string = '';
	name: string = '';
	showBanner: boolean = false;
	brandEnum = BrandEnum;
	favIcon: HTMLLinkElement = document.querySelector('#brand-icon')!;
	customerSupportEmail: string = ''
	customerSupportPhone: string = '';
	appVersion: string = environment.appVersion;
	
	constructor(private activateRoute: ActivatedRoute,
							private titleService: Title,
							private store: ReturnsStore,
							private router: Router) {
		this.displayBuildVersion();
		sessionStorage.setItem('lang', '');
		
		router.events.subscribe((route) => {
			if (route instanceof ActivationStart) {
				// this is a extreme fallback for case when data is corrupted for language in queryParam
				if(route.snapshot.queryParams.lang === 'undefined' || route.snapshot.queryParams.lang === undefined) {
					return;
				}
				
				if (sessionStorage.getItem('lang')) {
					this._translateService.use(sessionStorage.getItem('lang')!);
				} else {
					this._translateService.use(route.snapshot.queryParams.lang);
					sessionStorage.setItem('lang', route.snapshot.queryParams.lang);
				}
				this.brand = route.snapshot.params.brand;
				this._initData();
				const hasFormQueryParam = !!route.snapshot.queryParams.formonly ? route.snapshot.queryParams.formonly : 'false';
				
				// @ts-ignore
				if ([BrandEnum.Bugatti, BrandEnum.Elwin, BrandEnum.Bagatt, BrandEnum.daniel_hechter].indexOf(this.brand) === -1) {
					this.router.navigate(
						[],
						{
							relativeTo: activateRoute,
							queryParams: {
								formonly: hasFormQueryParam
							}
						}
					).then();
				}
				
				this.showBanner = route.snapshot.url[0].path.indexOf('check') > -1;
				this.hasForm = !route.snapshot.queryParams.formonly
					|| route.snapshot.queryParams.formonly === 'false';
				this.store.update({
					brand: this.brand,
					hasForm: hasFormQueryParam
				});
			}
		});
	}
	
	/**
	 * Method to init brand specific data
	 * @private
	 */
	private _initData(): void {
		// Branding specific assets
		switch (this.brand) {
			case (this.brandEnum.Elwin):
				this.titleService.setTitle('Elwin | Returns');
				this.name = this.brand;
				this.favIcon.href = './assets/img/elwin_icon.svg';
				this.store.update({
					orderNumberPlaceholder: '#11XX',
					orderNumberPrefix: '#',
					customerSupportEmail: 'loginPage.no_order_body_url_elwin',
					customerSupportPhone: 'loginPage.no_order_body_phone_elwin',
					iconSrc: 'elwin_icon.svg',
					bannerSrc: 'elwin_text.svg',
					brandName: 'ELWIN',
					brandNumber: '+49 (0) 6331 5145 7778',
					brandEmail: 'info@elwinshoes.com',
					heroImageSrc: 'https://amro0dev.blob.core.windows.net/returns-app-pictures/elwin_hero.jpg'
				});
				break;
			case (this.brandEnum.Bagatt):
				this.titleService.setTitle('TT.BAGATT | Returns');
				this.favIcon.href = './assets/img/bagatt_icon.svg';
				this.name = this.brand;
				this.store.update({
					orderNumberPlaceholder: 'BA-XXXX',
					orderNumberPrefix: 'BA-',
					customerSupportEmail: 'loginPage.no_order_body_url_bagatt',
					customerSupportPhone: 'loginPage.no_order_body_phone_bagatt',
					iconSrc: 'bagatt_icon.svg',
					bannerSrc: 'bagatt_text.svg',
					brandName: 'TT.BAGATT',
					brandNumber: '+49 (0)6331 5145-7776',
					brandEmail: 'service@tt.bagatt.com',
					heroImageSrc: 'https://amro0dev.blob.core.windows.net/returns-app-pictures/bagatt_hero.png',
					backUpHeroImageSrc: './assets/img/bagatt_hero.png'
				});
				break;
			case (this.brandEnum.daniel_hechter):
				this.titleService.setTitle('Daniel Hechter | Returns');
				this.favIcon.href = './assets/img/daniel_hechter_icon_blue.svg';
				this.name = 'Daniel Hechter';
				this.store.update({
					orderNumberPlaceholder: 'DH-XXXX',
					orderNumberPrefix: 'DH-',
					customerSupportEmail: 'loginPage.no_order_body_url_daniel_hechter',
					customerSupportPhone: 'loginPage.no_order_body_phone_daniel_hechter',
					iconSrc: 'daniel_hechter_icon_white.svg',
					bannerSrc: 'daniel_hechter_text_blue.svg',
					brandName: 'DANIEL HECHTER',
					brandNumber: '+49 6331 5145-7778',
					brandEmail: ' service@daniel-hechter-shoes.com',
					heroImageSrc: 'https://amro0dev.blob.core.windows.net/returns-app-pictures/daniel_hechter_hero.jpg',
					backUpHeroImageSrc: './assets/img/daniel_hechter_hero.jpg'
				});
				break;
			default:
				this.titleService.setTitle('Bugatti | Returns');
				this.favIcon.href = './assets/img/bugatti_white.svg';
				this.name = this.brand;
				this.store.update({
					orderNumberPlaceholder: 'B-XXXX',
					orderNumberPrefix: 'B-',
					customerSupportEmail: 'loginPage.no_order_body_url',
					customerSupportPhone: 'loginPage.no_order_body_phone',
					iconSrc: 'bugatti_white.svg',
					bannerSrc: 'bugatti_text.svg',
					brandName: 'BUGATTI',
					brandNumber: '+49 6331 5145-7777',
					brandEmail: 'service@bugatti-shoes.com',
					heroImageSrc: 'https://amro0dev.blob.core.windows.net/returns-app-pictures/bugatti_hero.png',
					backUpHeroImageSrc: './assets/img/bugatti_hero.png'
				});
				break;
		}
		
		this.customerSupportEmail = this.store.getCurrentState().customerSupportEmail;
		this.customerSupportPhone = this.store.getCurrentState().customerSupportPhone;
	}
	
	/**
	 * To display the current build version on the console
	 */
	displayBuildVersion(): void {
		console.log(
			`%cCurrent version: ${environment.appVersion}`,
			`background: #0082af; padding: 8px 12px; border-radius: 4px; color: #fafafa; font-size: x-large`
		);
	}
}
