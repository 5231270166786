<section id="form">
  <form *ngIf="loginForm"
        [formGroup]="loginForm">
    <div class="form-group">
      <label for="email">{{'loginPage.email' | translate}}</label>
      <input type="email"
             class="form-control form-item"
             id="email"
             formControlName="email"
             aria-describedby="emailHelp"
             placeholder="email@gmail.com">
      <small *ngIf="loginForm.controls.email.invalid && loginForm.controls.email.dirty"
             class="emailHelp form-text text-muted">{{'loginPage.email_help' | translate}}</small>
    </div>
    <div class="form-group">
      <label for="order">{{'loginPage.order_field_name' | translate}}</label>
      <input type="text"
             class="form-control form-item"
             id="order"
             formControlName="order_num"
             [placeholder]="orderNumberPlaceholder">
      <small *ngIf="loginForm.controls.order_num.invalid && loginForm.controls.order_num.dirty"
             class="emailHelp form-text text-muted">{{'loginPage.order_number_help' | translate}}</small>
    </div>
    <div class="form-group">
      <button type="submit"
              [disabled]="loginForm.invalid"
              (click)="appLogin()"
              class="btn">{{'loginPage.submit_button' | translate}}</button>
    </div>
  </form>
</section>

<!-- Modal order has expired Expired -->
<div class="modal fade"
     id="message_modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="message_modalLabel"
     aria-hidden="true">
  <div class="modal-dialog"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'loginPage.no_order_head' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>{{'loginPage.no_order_body' | translate}}</strong><br><br>
        <strong>{{'loginPage.no_order_body2' | translate}}</strong><br><br>
        <a href="mailto:{{customerSupportEmail | translate}}">
          {{customerSupportEmail | translate}}
        </a>{{customerSupportPhone | translate}}<br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal order not found -->
<div class="modal fade"
     id="error_modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="error_modalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'loginPage.error_head' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>{{'loginPage.error_body' | translate}}</strong><br><br>
        <strong>{{'loginPage.no_order_body2' | translate}}</strong><br><br>
        <a href="mailto:{{customerSupportEmail | translate}}">
          {{customerSupportEmail | translate}}
        </a>{{customerSupportPhone | translate}}<br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal customer support -->
<div class="modal fade"
     id="customer_support_modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="error_modalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'loginPage.error_head' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong>{{'loginPage.no_order_body2' | translate}}</strong><br><br>
        <a href="mailto:{{customerSupportEmail | translate}}">
          {{customerSupportEmail | translate}}
        </a>{{customerSupportPhone | translate}}<br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<app-brand-switcher *ngIf="isInDev"></app-brand-switcher>
