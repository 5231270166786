import {Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {BrandEnum} from '../../core/enums/brand.enum';
import {ReturnsStore} from "../../core/store/returns.state";
import {TranslateModule} from "@ngx-translate/core";


@Component({
	selector: 'app-login-image',
	templateUrl: './login-image.component.html',
	styleUrls: ['./login-image.component.scss'],
	standalone: true,
	imports: [TranslateModule]
})
export class LoginImageComponent implements OnInit {
	@ViewChild('image', {static: true, read: ElementRef}) imageContainer: ElementRef | undefined;
	@Input() brand: string = '';
	
	private _store = inject(ReturnsStore);
	
	imageSrc: string = this._store.getCurrentState().heroImageSrc;
	backUpImageSrc: string = this._store.getCurrentState().backUpHeroImageSrc;
	brandEnum = BrandEnum;
	
	ngOnInit(): void {
		this._changeImage();
	}
	
	/**
	 * Change image according to brand with fallback
	 * @private
	 */
	private _changeImage(): void {
		this.imageContainer?.nativeElement.style.setProperty('--hero-image', `url(${this.imageSrc}), url(${this.backUpImageSrc})`);
	}
}
