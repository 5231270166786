<br>

<div id="page_loader" class="page-loader">
  <div class="spinner"></div>
</div>

<div class="return-form"
     id="form">
  <div class="row">
    <div class="col-lg-3 col-md-12 col-sm-12 order-lg-1 order-md-2">
      <div class="row side-menu">
        <div class="sidebar-content">
          <span class="sidebar-title" id="my_account">{{ 'orderViewPage.my_account' | translate }}</span>
          <p id="sidebar-text-name" class="sidebar-text">{{ client.firstname }} {{ client.lastname }}</p>
        </div>
        <div class="sidebar-content">
          <span class="sidebar-title"></span>
          <p id="sidebar-text-address"
             class="sidebar-text">{{ client.billing_street }} {{ client.billing_number }}</p>
          <p id="sidebar-text-zip" class="sidebar-text">{{ client.billing_postcode }}</p>
          <p id="sidebar-text-city" class="sidebar-text">{{ client.billing_city }}</p>
        </div>
      </div>
      <div class="row side-menu-list">
        <p class="return-requests-p">{{ 'orderViewPage.return_card_title' | translate }}</p>
        <div *ngFor="let returnRequest of returns" class="card  m-b-5">
          <div class="card-body">
            <h6 class="card-title">{{ 'orderViewPage.return_card_date' | translate }}
              : {{ returnRequest.order_date }}</h6>
            <button [attr.data-return-no]="returnRequest.return_no" (click)="your_return_view($event);"
                    class="btn">{{ 'orderViewPage.return_card_button' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-lg-9 col-md-12 col-sm-12 order-lg-2 order-content order-md-1">
      <h2 id="your_order_text">{{ 'orderViewPage.your_order' | translate }}</h2>
      <!--      Mobile view of list      -->
      <div class="mobile-view">
        <div class="order-summary">
          <div>
            <div class="title">
              {{ 'orderViewPage.order_num' | translate }}
            </div>
            <div class="info">
              {{ order_header.order_num }}
            </div>
          </div>
          <div>
            <div class="title">
              {{ 'orderViewPage.status' | translate }}
            </div>
            <div class="info">
              {{ order_header.order_status }}
            </div>
          </div>
          <div>
            <div class="title">
              {{ 'orderViewPage.order_date' | translate }}
            </div>
            <div class="info">
              {{ order_header.order_date }}
            </div>
          </div>
          <div>
            <div class="title">
              {{ 'orderViewPage.total_value' | translate }}
            </div>
            <div class="info">
              {{ order_header.total_paid | number : '1.2-2' }} {{ order_header.currency }}
            </div>
          </div>
        </div>
        
        <div class="order-list">
          <div class="title">
            {{ 'orderViewPage.items' | translate | uppercase }}
          </div>
          <ng-container *ngFor="let orderItem of items; let i = index">
            <div class="mobile-card">
              <input *ngIf="orderItem.lineStatus === productStatusEnum.CLOSED"
                     class="order-checkbox"
                     id="{{orderItem.article_code}}"
                     type="checkbox"
                     [ngModel]="orderItem.checked"
                     (click)="checked_row(orderItem, i, $event)"
                     value="{{orderItem.checked}}"
                     name="{{orderItem.article_code}}">
              <div class="mobile-details">
                <div class="mobile-details"
                     (click)="onSelectReasonModal(orderItem, i)">
                  <div class="mobile-body">
                    <img class="sku-image"
                         src="{{orderItem.img}}"
                         height="120">
                    <div class="card-info">
                      <div>
                        {{ orderItem.article_code }}
                      </div>
                      <div *ngIf="orderItem.lineStatus === productStatusEnum.OPEN"
                           class="error">
                        {{ 'orderViewPage.article_not_available' | translate }}
                      </div>
                      <div class="item-info">
                        <div>
                          <div>
                            {{ orderItem.article_color }}
                          </div>
                          <div>{{ 'orderViewPage.size' | translate }} {{ orderItem.article_size }}</div>
                        </div>
                        <div>
                          {{ orderItem.price_with_discounts | number : '1.2-2' }} {{ order_header.currency }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="dropdown-btn"
                          *ngIf="orderItem.lineStatus === productStatusEnum.CLOSED">
                    <span
                        *ngIf="'orderViewPage.' + orderItem.return_reason | translate; else selectReason">{{ 'orderViewPage.' + (orderItem.return_reason || '-') | translate }}</span>
                    <ng-template #selectReason>
                      <span>{{ 'orderViewPage.please_select_reason' | translate }}</span>
                      <span> > </span>
                    </ng-template>
                  </button>
                </div>
                
                @if (orderItem.return_reason === 'R1') {
                  <textarea rows="4"
                            [class.invalid]="orderItem.invalidDamageReason || orderItem.invalidDamageReasonisTooLong"
                            (ngModelChange)="checkDamageReason(i)"
                            [placeholder]="'orderViewPage.r1_placeholder' | translate"
                            [(ngModel)]="orderItem.return_reason_description"
                            [ngModelOptions]="{standalone: true}">
                  </textarea>
                }
                
                @if (orderItem.return_reason === 'R8') {
                  <textarea rows="2"
                            [class.invalid]="orderItem.invalidDamageReasonisTooLong"
                            (ngModelChange)="checkDamageReason(i)"
                            [placeholder]="'orderViewPage.damage_placeholder' | translate"
                            [(ngModel)]="orderItem.damage_description"
                            [ngModelOptions]="{standalone: true}">
                  </textarea>
                }
                
                <span *ngIf="orderItem.invalidDamageReason"
                      class="error">{{ 'orderViewPage.please_add_damage_reason' | translate }}</span>
                <span *ngIf="orderItem.invalidDamageReasonisTooLong"
                      class="error">{{ 'orderViewPage.damage_reason_length' | translate }}</span>
              
              </div>
            </div>
          </ng-container>
        </div>
        <div class="button-mobile">
          <button type="button"
                  (click)="cancel()"
                  class="btn">{{ 'orderViewPage.cancel_return' | translate }}
          </button>
          <div class="download-btn">
            <button type="button"
                    [disabled]="invalidForm || disableDownloadButton"
                    [class.btn-inactive]="invalidForm || disableDownloadButton"
                    (click)="addReturn()"
                    class="btn">{{ 'orderViewPage.button_next' | translate }}
            </button>
            <div class="sku-color">{{ 'orderViewPage.info' | translate }}</div>
          </div>
        
        </div>
      </div>
      <!--      Desktop view of list      -->
      <div class="table-responsive">
        <form id="return_select">
          <table class="table table-sm order-table">
            <thead>
            <tr>
              <th id="th1_text" scope="col" colspan="3">{{ 'orderViewPage.order_num' | translate }}</th>
              <th id="th2_text" scope="col">{{ 'orderViewPage.status' | translate }}</th>
              <th id="th3_text" scope="col" class="text-center">{{ 'orderViewPage.items' | translate }}</th>
              <th id="th4_text" scope="col"></th>
              <th id="th5_text" scope="col">{{ 'orderViewPage.order_date' | translate }}</th>
              <th id="th6_text" scope="col" colspan="2">{{ 'orderViewPage.total_value' | translate }}</th>
            </tr>
            <tr>
              <th id="order_number" scope="col" colspan="3">{{ order_header.order_num }}</th>
              <th id="status" scope="col">{{ order_header.order_status }}</th>
              <th id="items" scope="col" class="text-center">{{ items.length }}</th>
              <th scope="col"></th>
              <th id="order_date" scope="col" class="w-100">{{ order_header.order_date }}</th>
              <th id="total_value" scope="col"
                  colspan="2">{{ order_header.total_paid | number : '1.2-2' }} {{ order_header.currency }}
              </th>
            </tr>
            </thead>
            <tbody id="table_body">
            <tr *ngFor="let item of items; let i = index"
                [class.checked]="item.checked"
                id="sku-{{item.article_code}}">
              <td [attr.colspan]="4">
                <label class="checkbox">
                  <input *ngIf="item.lineStatus === productStatusEnum.CLOSED"
                         class="order-checkbox"
                         id="{{item.article_code}}"
                         type="checkbox"
                         value="{{item.checked}}"
                         (click)="checked_row(item, i)"
                         name="sku">
                  <span></span>
                  <span class="order-sku">
                    {{ item.article_code }}
                    <div *ngIf="item.lineStatus === productStatusEnum.OPEN"
                         class="error">
                      {{ 'orderViewPage.article_not_available' | translate }}
                    </div>
                  </span>
                </label>
              </td>
              <td>
                <div class="item">
                  <img class="sku-image" src="{{item.img}}" height="100">
                  <div class="sku-detail" style="margin-left: 10px">
                    <span class="sku-group">{{ item.article_group }}</span>
                    <span class="sku-color">{{ item.article_color }}</span>
                    <span class="sku-size">{{ 'orderViewPage.size' | translate }} {{ item.article_size }}</span>
                  </div>
                </div>
              </td>
              <td [attr.colspan]="3">
                <div class="dropdown"
                     *ngIf="item.lineStatus === productStatusEnum.CLOSED"
                     [class.invalid]="item.invalidReason || item.invalidDamageReason || item.invalidDamageReasonisTooLong">
                  <select (change)="onSelect($event, i)">
                    <option *ngFor="let reason of returnReasons"
                            [value]="reason">
                      {{ 'orderViewPage.' + reason | translate }}
                    </option>
                  </select>
                  
                  @if (item.return_reason === 'R1') {
                    <textarea rows="4"
                              [class.invalid]="item.invalidDamageReasonisTooLong"
                              (ngModelChange)="checkDamageReason(i)"
                              [placeholder]="'orderViewPage.r1_placeholder' | translate"
                              [(ngModel)]="item.return_reason_description"
                              [ngModelOptions]="{standalone: true}">
                    </textarea>
                  }
                  
                  @if (item.return_reason === 'R8') {
                    <textarea rows="2"
                              [class.invalid]="item.invalidDamageReason || item.invalidDamageReasonisTooLong"
                              (ngModelChange)="checkDamageReason(i)"
                              [placeholder]="'orderViewPage.damage_placeholder' | translate"
                              [(ngModel)]="item.damage_description"
                              [ngModelOptions]="{standalone: true}">
                    </textarea>
                  }
                  
                  <span
                      *ngIf="item.invalidDamageReason">{{ 'orderViewPage.please_add_damage_reason' | translate }}</span>
                  <span
                      *ngIf="item.invalidDamageReasonisTooLong">{{ 'orderViewPage.damage_reason_length' | translate }}</span>
                  <span *ngIf="item.invalidReason">{{ 'orderViewPage.please_select_reason' | translate }}</span>
                </div>
              </td>
              <td colspan="2">{{ item.price_with_discounts | number : '1.2-2' }} {{ order_header.currency }}</td>
            
            <tr class="no-opacity">
              <td colspan="4">
                <button type="button"
                        id="cancel-return"
                        style="min-width:220px !important;"
                        (click)="cancel()"
                        class="btn">{{ 'orderViewPage.cancel_return' | translate }}
                </button>
              </td>
              <td colspan="5">
                <button type="button"
                        style="width:220px !important;"
                        [disabled]="invalidForm || disableDownloadButton"
                        [class.btn-inactive]="invalidForm || disableDownloadButton"
                        id="next-btn"
                        (click)="addReturn()"
                        class="btn">{{ 'orderViewPage.button_next' | translate }}
                </button>
              </td>
            </tr>
            <tr>
              <td class="no-border-top"
                  colspan="9"><span class="sku-color">{{ 'orderViewPage.info' | translate }}</span></td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="quantity_error" tabindex="-1" role="dialog" aria-labelledby="quantity_errorLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="quantity_errorLabel">{{ 'orderViewPage.modal_quantity_error' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong
            id="return_reason_error_text">{{ 'orderViewPage.modal_quantity_error_text' | translate }}</strong><br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="no_order_item" tabindex="-1" role="dialog" aria-labelledby="no_order_itemLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="return_reason_errorLabel">Order item error!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong id="no_order_item_text">You can't return articles from this order!</strong><br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- print preview -->
<app-print-label *ngIf="request?.addresses"
                 [productBrand]="productBrand"
                 [general_data]="general_data"
                 [client]="client"
                 [items]="request.order_items"></app-print-label>

<div id="process_loading" class="loading2 no-display">
  <div class="loading">
    <div style="text-align: center">
      <h3 class="threeD-text">
        <div>
          {{ 'orderViewPage.process_request_1' | translate }}
        </div>
        <div class="text-break">
          {{ 'orderViewPage.process_request_2' | translate }}
        </div>
      </h3>
      <div style="text-align: center">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</div>
