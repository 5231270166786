import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgFor, UpperCasePipe} from "@angular/common";
import {Component, inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {ReturnsStore} from "../../../core/store/returns.state";
import {TranslateModule} from "@ngx-translate/core";


@Component({
	selector: 'app-select-reason-modal',
	templateUrl: './select-reason-modal.component.html',
	styleUrls: ['./select-reason-modal.component.scss'],
	standalone: true,
	imports: [NgFor, ReactiveFormsModule, FormsModule, UpperCasePipe, MatDialogModule, TranslateModule]
})
export class SelectReasonModalComponent implements OnInit {
	private _dialogRef = inject(MatDialogRef<SelectReasonModalComponent>);
	private _dialogConfig = inject(MAT_DIALOG_DATA);
	private _store = inject(ReturnsStore);
	
	selectedReason: string = '';
	returnReasons: string[] = this._store.getCurrentState().returnReasons;
	
	ngOnInit(): void {
		this.selectedReason = this._dialogConfig;
	}
	
	selectReason(reason?: string) {
		this._dialogRef.close(reason);
	}
	
}
