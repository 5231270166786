<div mat-dialog-title
     class="card-title">
    <h1>{{'orderViewPage.please_select_reason' | translate | uppercase}}</h1>
    <div (click)="selectReason()">X</div>
</div>
<div class="reason-list">
    <label *ngFor="let reason of returnReasons"
           (click)="selectReason(reason)">
        <input type="radio"
               [ngModel]="selectedReason"
               [value]="reason">
        {{'orderViewPage.' + reason | translate}}
    </label>
</div>
