import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {OrderViewComponent} from './components/order-view/order-view.component';
import {YourReturnComponent} from './components/your-return/your-return.component';
import {LanguageGuard} from "./language.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'check-order/bugatti',
    pathMatch: 'full'
  },
  {
    path: 'check-order/:brand',
    component: LoginComponent,
    canActivate: [LanguageGuard]
  },
  {
    path: 'orders/:brand',
    component: OrderViewComponent,
    canActivate: [LanguageGuard]
  },
  {
    path: 'your_return/:brand',
    component: YourReturnComponent,
    canActivate: [LanguageGuard]
  },
  {
    path: '**',
    redirectTo: 'check-order/bugatti'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
