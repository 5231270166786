<div id="print-container"
     class="print-container">
  <div class="pdf-header container-fluid print-section">
    <img src="assets/img/{{productBrand}}_white.png"
         class="{{productBrand}}-logo"
         alt="{{productBrand}}">
  </div>
  
  <div class="container-fluid print-section">
    <div class="">
      <p style="text-align: center; margin-top: 10px; font-size: 32px; margin-bottom: 0px;">{{'returnsViewPage.return_form_text' | translate}}</p>
    </div>
    <div class="row pdf-section1">
      <div class="col col-7">
        <strong>{{'returnsViewPage.return_number' | translate}}: </strong> <span
          id='return_no_print'>{{general_data.return_no}}</span><br>
        <strong>{{'returnsViewPage.order_num' | translate}}: </strong> <span id='order_print'>{{general_data.order_num}}</span>
      </div>
      <div class="col col-5 float-right">
        <strong class="text-right">{{'returnsViewPage.order_date' | translate}}: </strong> <span
          id='order_date_print'>{{general_data.order_date}}</span>
      </div>
    </div>
  </div>
  
  <div class="container-fluid print-section">
    <div class="row pdf-section2">
      <div class="col pos-relative"
           style="padding-right: 80px !important;">
        <canvas class="barcode pdf_barcode"></canvas>
      </div>
    </div>
    <div class="row">
      <div class="col col-6 ">
        <h2 id="pdf-h-1">{{'returnsViewPage.pdf_h_1' | translate}}</h2>
        <p class="fs-22 m-b-5"
           *ngFor="let returnAdress of returnAddresses; let i = index"
           [class.m-t-20]="i === 0">
          {{returnAdress}}
        </p>
      </div>
      <div class="col col-6">
        <h2 id="pdf-h-2">{{'returnsViewPage.pdf_h_2' | translate}}</h2>
        <p class="fs-22 m-b-5 m-t-20" id="name_print">{{client.firstname}} {{client.lastname}}</p>
        <p class="fs-22 m-b-5" id="street_print">{{client.shiping_street}} {{client.shiping_number}}</p>
        <p class="fs-22 m-b-5" id="zip_city_print">{{client.shiping_postcode}} {{client.shiping_city}}</p>
      </div>
    </div>
    
    <div class="row pdf-section3">
      <h3 class="m-t-80 m-l-0" id="pdf-h2">{{'returnsViewPage.pdf_h2' | translate}}</h3>
      
      <table class="table table-bordered">
        <thead>
        <tr>
          <th id="pdf_th_1">{{'returnsViewPage.pdf_th_1' | translate}}</th>
          <th id="pdf_th_3">{{'returnsViewPage.pdf_th_3' | translate}}</th>
          <th>{{'returnsViewPage.pdf_th_4' | translate}}</th>
        </tr>
        </thead>
        <tbody id="print_table">
        <tr *ngFor="let item of items">
          <td>{{item.article_code ?? item.articlecode}}</td>
          <td>{{item.return_reason}}</td>
          <td>
            <span [class.font-weight-bold]="item.damage_description">{{'returnsViewPage.' + item.return_reason | translate}}</span> <span
              *ngIf="item.damage_description || item.return_reason_description">:</span>
            <div *ngIf="item.damage_description"
                 class="break-word">
              {{item.damage_description}}
            </div>
            <div *ngIf="item.return_reason_description"
                 class="break-word">
              {{item.return_reason_description}}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    
    <div class="row pdf-section4 m-t-20">
      <div class="col">
        <h1 id="return_instructions_title">{{'returnsViewPage.pdf_return_instructions_header' | translate}}</h1>
        <div [innerHTML]="'returnsViewPage.return_instruction' | translate"></div>
      </div>
      
      <div class="bold-info">
        {{'returnsViewPage.include_form' | translate}}
      </div>
    </div>
  </div>
</div>
