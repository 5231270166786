<div *ngIf="hasForm && brand">
  <app-header [brand]="name"
              [brandName]="brand"></app-header>
  <app-login-image *ngIf="showBanner"
                   [brand]="brand"></app-login-image>
</div>
<router-outlet></router-outlet>
<span [hidden]="true">{{appVersion}}</span>

<!-- Modal There seemed to be an error -->
<div class="modal fade"
     id="error-modal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="message_modalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'loginPage.error_head' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <strong id="return_printed">{{'loginPage.generic_server_error' | translate}}</strong><br><br>
        <strong id="return_printed">{{'loginPage.no_order_body2' | translate}}</strong><br><br>
        <a href="mailto:{{customerSupportEmail | translate}}" id="return_printed">
          {{customerSupportEmail | translate}}
        </a>{{customerSupportPhone | translate}}<br><br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn fs-14 m-r-a m-l-0" id="no_btn" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
