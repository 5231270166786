import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	headers = new HttpHeaders();
	
	constructor(private httpClient: HttpClient) {
		this.headers = this.headers.append('Content-Type', 'application/json');
		this.headers = this.headers.append('Authorization', 'Basic ' + btoa(`${environment.user}:${environment.pass}`));
	}
	
	read_data(email: any, order_num: any): Observable<any> {
		return this.httpClient.get<any>(`${environment.api_url}/order_view`, {headers: this.headers, params: {
				email: email,
				order: order_num,
				last_days: 60
			}});
	}
	
	your_return(email: any, order_num: any, items: any): Observable<any> {
		let params = new HttpParams();
		params = params.append('email', email);
		params = params.append('order', order_num);
		params = params.append('last_days', 60);
		params = params.append('items', items);
		return this.httpClient.get<any>(`${environment.api_url}/order_view`, {headers: this.headers, params: params});
	}
	
	get_your_return_document(email: any, order_num: any, items: any): Observable<any> {
		let params = new HttpParams();
		params = params.append('email', email);
		params = params.append('order', order_num);
		params = params.append('last_days', 60);
		params = params.append('return_no', items);
		return this.httpClient.get<any>(`${environment.api_url}/your_return_document`, {
			headers: this.headers,
			params: params
		});
	}
	
	add_request(body: any): Observable<any> {
		return this.httpClient.post<any>(`${environment.api_url}/add_request`, body, {headers: this.headers});
	}
	
	updateReturnRequestHeaderData(header: any, item: any): Observable<any> {
		header.grand_total += item.price_with_discounts;
		header.total_paid += item.price_with_discounts;
		header.subtotal += item.line_total_per_quantity - item.doc_disc_per_quantity;
		header.discount_amount += item.doc_disc_per_quantity;
		return header;
	}
	
	createReturnRequestOrderItems(item: any, client: any): any {
		return {
			ean: item.ean,
			articlecode: item.article_code,
			articlename: item.article_name,
			original_price: item.original_price,
			selling_price: item.selling_price,
			warehouse: this.getCorrectWarehouseNumber(item.warehouse, client),
			currency: item.currency,
			discount_ref: '',
			discount_value: '0.0000',
			discount_percent: '0.0000',
			order_discount: '0.0000',
			damage_description: item.return_reason === 'R8' ? item.damage_description : null,
			return_reason_description: item.return_reason === 'R1' ? item.return_reason_description : null,
			line_total: (item.line_total_per_quantity).toFixed(3),
			return_reason: item.return_reason,
			link: ''
		};
	}
	
	private getCorrectWarehouseNumber(warehouse: any, client: any) {
		if (client.shiping_country == 'CH') {
			return 'CH01';
		} else {
			return warehouse;
		}
	}
	
	get_returns(order_num: any): Observable<any> {
		return this.httpClient.get<any>(`${environment.api_url}/get_returns`, {headers: this.headers, params: {
				order: order_num
			}});
	}
	
}
