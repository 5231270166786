import {Component, inject, Input} from '@angular/core';
import {BrandEnum} from '../../core/enums/brand.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {ReturnsStore} from "../../core/store/returns.state";
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {NgIf, NgFor, UpperCasePipe} from '@angular/common';
import {TranslateModule, TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss'],
	standalone: true,
	imports: [NgIf, ReactiveFormsModule, FormsModule, NgFor, UpperCasePipe, TranslateModule]
})
export class HeaderComponent {
	@Input() brand: string = '';
	@Input() brandName: string = '';
	
	private _store = inject(ReturnsStore);
	private _activatedRoute = inject(ActivatedRoute);
	private _router = inject(Router);
	private _translateService = inject(TranslateService);
	
	brandEnum = BrandEnum;
	brandNameToDisplay: string = this._store.getCurrentState().brandName;
	iconSrc: string = this._store.getCurrentState().iconSrc;
	bannerSrc: string = this._store.getCurrentState().bannerSrc;
	brandNumber: string = this._store.getCurrentState().brandNumber;
	brandEmail: string = this._store.getCurrentState().brandEmail;
	languages = this._store.getCurrentState().languages;
	selectedLanguage = this._activatedRoute.snapshot.queryParams.lang || 'en';
	
	/**
	 * Navigate according to brand
	 */
	navigate(outside: boolean = false): void {
		if (this.brand === this.brandEnum.Bagatt && outside) {
			window.location.href = 'https://www.bagatt.com/';
			return;
		}
		this._router.navigateByUrl(`check-order/${this.brandName}`).then();
	}
	
	/**
	 * Method to switch language
	 * @param lang
	 */
	onLanguageSwitch(lang: any): void {
		sessionStorage.setItem('lang', lang.target.value);
		this._translateService.use(lang.target.value);
	}
}
