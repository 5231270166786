import {createStore, withProps} from "@ngneat/elf";
import {Injectable} from "@angular/core";

interface ReturnsState {
	brand: string;
	brandName: string;
	hasForm: string;
	lang: string;
	orderNumberPlaceholder: string;
	orderNumberPrefix: string;
	customerSupportEmail: string;
	customerSupportPhone: string;
	iconSrc: string;
	bannerSrc: string;
	brandNumber: string;
	heroImageSrc: string;
	backUpHeroImageSrc: string;
	brandEmail: string;
	source: string;
	returnReasons: string[];
	languages: string[];
}

const initialState: ReturnsState = {
	brand: '',
	brandName: '',
	hasForm: 'false',
	lang: '',
	orderNumberPlaceholder: '',
	orderNumberPrefix: '',
	customerSupportEmail: '',
	customerSupportPhone: '',
	iconSrc: '',
	bannerSrc: '',
	brandNumber: '',
	heroImageSrc: '',
	backUpHeroImageSrc: '',
	brandEmail: '',
	source: '',
	returnReasons: ['R1', 'R2', 'R3', 'R4', 'R5', 'R6', 'R7', 'R8'],
	languages: ['en', 'de', 'fr', 'it', 'dk']
}

const returnsStore = createStore({name: 'returnsStore'}, withProps<ReturnsState>(initialState));

@Injectable({
	providedIn: 'root'
})
export class ReturnsStore {
	
	update(data: Partial<ReturnsState>) {
		returnsStore.update((state: ReturnsState)=> ({
			...state,
			...data
		}));
	}
	
	/**
	 * Method to update state according to prop and data
	 * @param options
	 */
	updateState(options: {prop: string , data: any}) {
		returnsStore.update((state: ReturnsState)=> ({
			...state,
			[options.prop]: options.data
		}))
	}
	
	/**
	 * Method to return current state
	 */
	getCurrentState() {
		return returnsStore.query((state: ReturnsState) => state);
	}
	
	
}