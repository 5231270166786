import {Component, inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {ReturnsStore} from "../../core/store/returns.state";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-error-notification-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './error-notification-modal.component.html',
  styleUrls: ['./error-notification-modal.component.scss']
})
export class ErrorNotificationModalComponent implements OnInit {
  private _store = inject(ReturnsStore);
  private _dialogConfig = inject(MAT_DIALOG_DATA);
  private _modalRef = inject(MatDialogRef<ErrorNotificationModalComponent>)
  
  customerSupportEmail: string = this._store.getCurrentState().customerSupportEmail;
  customerSupportPhone: string = this._store.getCurrentState().customerSupportPhone;
  title = '';
  message  =  '';
  
  ngOnInit():void {
    this.title = this._dialogConfig.title;
    this.message = this._dialogConfig.message;
  }
  
  close(): void {
    this._modalRef.close()
  }
}
